import React from 'react';
import {MyContext} from '../../context/AuthContext';
import '../../css/inicio.css';
import colores from '../../css/bootstrap.scss';
import toast, { Toaster } from 'react-hot-toast';
import Ingresos from './Ingresos/Ingresos';
import CostoVenta from './Gastos/CostoVenta';
import GastosOperativos from './Gastos/GastosOperativos';
import RepartoUtilidades from './Gastos/RepartoUtilidades';
import Inversiones from './Gastos/Inversiones';
import { Modal } from 'react-bootstrap';
import { parseISO } from 'date-fns'

class Registrar extends React.Component {

    static contextType = MyContext;

    constructor(){
        super();
        this.state = {
            fecha: this.formatDate(new Date().toLocaleDateString()),
            ruta: 'Registrar',
            existeCorte: false,
            estatusCorte: false,
            dataCorte: [],
            showModal: false,
            cuentas: []
        }
    }

    componentDidMount = async () => {
        this.context.cambiarSubruta('');
        this.context.cambiarRuta('Registrar');
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
        this.selectCorte();
        this.selectDatosCorte();
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
            if (this.state.ruta === 'Registrar') {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 210) + 'px';
            }else{
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = '70px';
            }
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    selectCorte = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'comprobar', fecha: this.state.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/cortes.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.mensaje !== false) {
                this.setState({
                    existeCorte: true,
                    estatusCorte: !!Number(data.corte.cerrado)
                })
            }else{
                this.setState({
                    existeCorte: false
                })
            }
        }else{
            toast.error('Error al buscar registros.');
        }
    }

    selectDatosCorte = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectDatosDia', fecha: this.state.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/cortes.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                dataCorte: data
            })
        }else{
            toast.error('Error al obtener datos de corte.');
        }
    }

    volver = async () => {
        if (this.context.rootState.saved) { 
            await this.setState({
                ruta: 'Registrar'
            });
            this.getHeightWindow();
            this.selectCorte();
            this.selectDatosCorte();
            this.context.cambiarSubruta('');
        }else{
            if (window.confirm('¿Continuar sin guardar cambios?')) {                
                await this.setState({
                    ruta: 'Registrar'
                });
                this.getHeightWindow();
                this.selectCorte();
                this.selectDatosCorte();  
                this.context.saveChanges();
            }
        }
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false
        })
    }

    onChangeFecha = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        });
        this.selectCorte();
        this.selectDatosCorte();
    }

    onChangeRuta = async (ruta) => {
        await this.setState({
            ruta: ruta
        });
        this.getHeightWindow();
    }
    
    onChangeRutaGasto = async (ruta) => {
        await this.setState({
            ruta: ruta
        });
        this.getHeightWindow();
    }

    getEfectivo = () => {
        let efectivo = this.state.dataCorte.ingresosEfectivo - this.state.dataCorte.costoVentaEfectivo - this.state.dataCorte.gastosOperativosEfectivo - this.state.dataCorte.repartoUtilidadesEfectivo;
        return efectivo
    }

    hacerCorte = async () => {
        if (this.state.existeCorte) {
            this.showModal();
        }else{
            toast.error('Para eso, debe registrar algún ingreso o gasto.');
        }
    }

    registrar = async () => {
        let efectivoDia = this.state.dataCorte.efectivoDia;
        if (efectivoDia < 0) {
            toast.error('El efectivo del dia no puede ser negativo.');
        }else{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'hacerCorte', fecha: this.state.fecha, efectivoDia: efectivoDia, cuentas: this.state.dataCorte.cuentas, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.panaderialazcano.com/php/DB/cortes.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.hideModal();
                this.selectCorte();
                this.selectDatosCorte();
                this.reloadHeader();
            }else{
                toast.error('Error al realizar corte.');
                console.log(data.mensaje);
            }
        }
    }

    reloadHeader = () => {
        document.getElementById('btnHeaderReload').click();
    }

    render() { 
            return ( 
            <>
                <div className="contenedorPrincipal p-0 m-0 bg-light">
                    <div className="row w-100 m-0">
                        <div className="col-12 p-0 px-2" style={{ height: '70px' }}>
                            <div className='mx-auto' style={{ maxWidth: '350px' }}>
                                <div className="row pt-3 m-0">
                                    <div className={"col-2 d-flex justify-content-center " + (this.state.ruta === 'Registrar' ? 'd-none' : 'd-flex')}>
                                        <div className="elemento bg-secondary py-2 mx-auto" style={{ width: 'fit-content' }}>
                                            <button className='btn text-center p-0 mx-auto text-white' style={{ borderRadius: '0px', fontSize: '16px', width: 'fit-content', border: 'none', textTransform: 'capitalize' }} onClick={() => this.volver()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={'white'} className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={(this.state.ruta === 'Registrar' ? 'col-12' : 'col-10') + " px-1"}>
                                            <div className="inputGroup">
                                                <label htmlFor='fechaInput' style={{ textTransform: 'capitalize' }}>{parseISO(this.state.fecha).toLocaleString('es-MX', {weekday:'long'})}</label>
                                                <input type="date" id='fechaInput' className='form-control inputUnderline text-center p-0 mx-auto text-white' style={{ borderRadius: '0px', fontSize: '16px', width: 'fit-content', textTransform: 'capitalize' }} name="fecha" value={this.state.fecha} onChange={this.onChangeFecha}/>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" hidden={this.state.ruta !== 'Registrar'}>
                            <div className='mx-auto' style={{ maxWidth: '500px' }}>
                                <div className="row d-flex justify-content-center mt-2">
                                    <div className="col-4">
                                        <button className={'btn btnInicio p-0'} style={{ height: '70px', backgroundColor: '#D1FFE3' }} onClick={() => this.onChangeRuta('Ingresos')}>
                                            <i className="bi bi-cash-coin" style={{ fontSize: '30px' }}></i>
                                            <p className='m-0' style={{ fontSize: '10px' }}>Ingresos</p>
                                        </button>
                                    </div>
                                    <div className="col-4">
                                        <button className={'btn btnInicio p-0'} style={{ height: '70px', backgroundColor: '#FEF6FF' }} onClick={() => this.onChangeRutaGasto('Costo de Venta')}>
                                            <i className="bi bi-wallet2" style={{ fontSize: '30px' }}></i>
                                            <p className='m-0' style={{ fontSize: '10px' }}>Costos de Venta</p>
                                        </button>
                                    </div>
                                    <div className="col-4">
                                        <button className={'btn btnInicio p-0'} style={{ height: '70px', backgroundColor: '#FFFADE' }} onClick={() => this.onChangeRutaGasto('Gastos Operativos')}>
                                            <i className="bi bi-droplet" style={{ fontSize: '30px' }}></i>
                                            <p className='m-0' style={{ fontSize: '10px' }}>Gastos Operativos</p>
                                        </button>
                                    </div>
                                    <div className="col-4 mt-4">
                                        <button className={'btn btnInicio p-0'} style={{ height: '70px', backgroundColor: '#D3E5EF' }} onClick={() => this.onChangeRutaGasto('Reparto de Utilidades')}>
                                            <i className="bi bi-cash-stack" style={{ fontSize: '30px' }}></i>
                                            <p className='m-0' style={{ fontSize: '10px' }}>Reparto de Utilidades</p>
                                        </button>
                                    </div>
                                    <div className="col-4 mt-4">
                                        <button className={'btn btnInicio p-0'} style={{ height: '70px', backgroundColor: '#f9d4ea' }} onClick={() => this.onChangeRutaGasto('Inversiones')}>
                                            <i className="bi bi-graph-up-arrow" style={{ fontSize: '30px' }}></i>
                                            <p className='m-0' style={{ fontSize: '10px' }}>Inversiones</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light">
                    <div className="contenedorCorte mx-auto bg-white" hidden={this.state.ruta !== 'Registrar'} style={{ color: '', maxWidth: '500px' }}>
                        <div className="row m-0 h-100">
                            <div className="col-6" style={{ borderRight: '1px solid ' + colores.light, color: '', paddingBlock: '10px' }}>
                                Ingresos:
                            </div>
                            <div className="col-6 text-end" style={{ color: '', paddingBlock: '10px' }}>
                                $ {this.numberWithCommas(this.state.dataCorte.totalIngresos)}
                            </div>
                            <div className="col-6" style={{ borderTop: '1px solid ' + colores.light, borderRight: '1px solid ' + colores.light, color: '', paddingBlock: '10px' }}>
                                Gastos:
                            </div>
                            <div className="col-6 text-end" style={{ borderTop: '1px solid ' + colores.light, color: '', paddingBlock: '10px' }}>
                                $ {this.numberWithCommas(this.state.dataCorte.totalGastos)}
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center py-2" style={{ borderTop: '1px solid ' + colores.light }}>
                                {
                                    (this.state.existeCorte && this.state.estatusCorte) ?
                                        <button className='btn btn-lg btn-secondary text-white btnRadius mx-3' style={{  }} disabled>Corte Hecho</button>
                                    :
                                        <button className='btn btn-lg btn-secondary text-white btnRadius mx-3' style={{  }} onClick={this.hacerCorte}>Hacer Corte</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light">
                    <div className='mx-auto' style={{ maxWidth: '500px' }}>
                        {
                            this.state.ruta === 'Ingresos' && <Ingresos fecha={this.state.fecha} estatusCorte={this.state.estatusCorte}/>
                        }
                        {
                            this.state.ruta === 'Costo de Venta' && <CostoVenta fecha={this.state.fecha} estatusCorte={this.state.estatusCorte}/>
                        }
                        {
                            this.state.ruta === 'Gastos Operativos' && <GastosOperativos fecha={this.state.fecha} estatusCorte={this.state.estatusCorte}/>
                        }
                        {
                            this.state.ruta === 'Reparto de Utilidades' && <RepartoUtilidades fecha={this.state.fecha} estatusCorte={this.state.estatusCorte}/>
                        }
                        {
                            this.state.ruta === 'Inversiones' && <Inversiones fecha={this.state.fecha} estatusCorte={this.state.estatusCorte}/>
                        }
                    </div>
                </div>
                <Modal show={this.state.showModal} onHide={this.hideModal} centered>
                    <Modal.Header className='degradado'>
                        <h5 style={{ color: 'white' }} className='m-0'>{this.state.estatusCorte ? 'Editiar' : 'Hacer'} Corte</h5>
                    </Modal.Header>
                    <Modal.Body className='bg-light py-3' style={{ color: 'black' }}>
                        <div className="row">
                            <div className="col-12">
                                <h6 className='text-center'>Efectivo</h6>
                            </div>
                            <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                Ingresos
                            </div>
                            <div className="col-5 text-end mt-2">
                                <div className="input-group elemento p-0">
                                    <span className='input-group-text' style={{ height: '30px', color: colores.success, borderColor: colores.success }}>$</span>
                                    <input className='form-control text-end' style={{ height: '30px', borderColor: colores.success, color: colores.success }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.ingresosEfectivo)}/>
                                </div>
                            </div>
                            <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                Costo de Venta
                            </div>
                            <div className="col-5 text-end mt-2">
                                <div className="input-group elemento p-0">
                                    <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                    <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.costoVentaEfectivo)}/>
                                </div>
                            </div>
                            <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                Gastos Operativos
                            </div>
                            <div className="col-5 text-end mt-2">
                                <div className="input-group elemento p-0">
                                    <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                    <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.gastosOperativosEfectivo)}/>
                                </div>
                            </div>
                            <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                Reparto de Utilidades
                            </div>
                            <div className="col-5 text-end mt-2">
                                <div className="input-group elemento p-0">
                                    <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                    <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.repartoUtilidadesEfectivo)}/>
                                </div>
                            </div>
                            <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                Inversiones
                            </div>
                            <div className="col-5 text-end mt-2">
                                <div className="input-group elemento p-0">
                                    <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                    <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.inversionesEfectivo)}/>
                                </div>
                            </div>
                            <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                Quedan
                            </div>
                            <div className="col-5 text-end mt-2">
                                <div className="input-group elemento p-0">
                                    <span className='input-group-text' style={{ height: '30px', color: 'black', borderColor: 'black' }}>$</span>
                                    <input className='form-control text-end' style={{ height: '30px', color: 'black', borderColor: 'black' }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.efectivoDia)}/>
                                </div>
                            </div>
                           {
                                this.state.dataCorte.cuentas &&
                                    this.state.dataCorte.cuentas.map((e,index) =>
                                        <div className="col-12" key={index}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <hr />
                                                    <h6 className='text-center'>{e.nombreCuenta}</h6>
                                                </div>
                                                <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                                    Saldo
                                                </div>
                                                <div className="col-5 text-end mt-2">
                                                    <div className="input-group elemento p-0">
                                                        <span className='input-group-text' style={{ height: '30px', color: 'black', borderColor: 'black' }}>$</span>
                                                        <input className='form-control text-end' style={{ height: '30px', color: 'black', borderColor: 'black' }} type="text" readOnly value={this.numberWithCommas(e.saldo)}/>
                                                    </div>
                                                </div>
                                                <div className={"col-7 mt-2 " + (!e.ingresos > 0 ? 'd-none' : 'd-flex align-items-center')} style={{ fontSize: '15px' }}>
                                                    <p className='m-0 my-auto'>Ingresos</p>{e.idCuenta === '1' && <p className='m-0 my-auto ms-1' style={{ fontSize: '10px', color: 'gray' }}>Sumando efectivo del día</p>}
                                                </div>
                                                <div className="col-5 text-end mt-2" hidden={!e.ingresos > 0}>
                                                    <div className="input-group elemento p-0">
                                                        <span className='input-group-text' style={{ height: '30px', color: colores.success, borderColor: colores.success }}>$</span>
                                                        <input className='form-control text-end' style={{ height: '30px', borderColor: colores.success, color: colores.success }} type="text" readOnly value={this.numberWithCommas(e.ingresos)}/>
                                                    </div>
                                                </div>
                                                <div className="col-7 mt-2" style={{ fontSize: '15px' }} hidden={!e.costoVenta > 0}>
                                                    <p className='m-0 my-auto d-flex'>Costo de Venta</p>
                                                </div>
                                                <div className="col-5 text-end mt-2" hidden={!e.costoVenta > 0}>
                                                    <div className="input-group elemento p-0">
                                                        <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                                        <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(e.costoVenta)}/>
                                                    </div>
                                                </div>
                                                <div className="col-7 mt-2" style={{ fontSize: '15px' }} hidden={!e.gastosOperativos > 0}>
                                                    <p className='m-0 my-auto d-flex'>Gastos Operativos</p>
                                                </div>
                                                <div className="col-5 text-end mt-2" hidden={!e.gastosOperativos > 0}>
                                                    <div className="input-group elemento p-0">
                                                        <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                                        <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(e.gastosOperativos)}/>
                                                    </div>
                                                </div>
                                                <div className="col-7 mt-2" style={{ fontSize: '15px' }} hidden={!e.repartoUtilidades > 0}>
                                                    <p className='m-0 my-auto d-flex'>Reparto de Utilidades</p>
                                                </div>
                                                <div className="col-5 text-end mt-2" hidden={!e.repartoUtilidades > 0}>
                                                    <div className="input-group elemento p-0">
                                                        <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                                        <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(e.repartoUtilidades)}/>
                                                    </div>
                                                </div>
                                                <div className="col-7 mt-2" style={{ fontSize: '15px' }} hidden={!e.inversiones > 0}>
                                                    <p className='m-0 my-auto d-flex'>Inversiones</p>
                                                </div>
                                                <div className="col-5 text-end mt-2" hidden={!e.inversiones > 0}>
                                                    <div className="input-group elemento p-0">
                                                        <span className='input-group-text' style={{ height: '30px', color: colores.danger, borderColor: colores.danger }}>$</span>
                                                        <input className='form-control text-end' style={{ height: '30px', borderColor: colores.danger, color: colores.danger }} type="text" readOnly value={this.numberWithCommas(e.inversiones)}/>
                                                    </div>
                                                </div>
                                                <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                                    Nuevo Saldo
                                                </div>
                                                <div className="col-5 text-end mt-2">
                                                    <div className="input-group elemento p-0">
                                                        <span className='input-group-text' style={{ height: '30px', color: 'black', borderColor: 'black' }}>$</span>
                                                        <input className='form-control text-end' style={{ height: '30px', color: 'black', borderColor: 'black' }} type="text" readOnly value={this.numberWithCommas(e.nuevoSaldo)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                           }
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='bg-light'>
                        <button className='btn btn-primary text-white btnRadius' style={{ width: '30%' }} onClick={() => this.hideModal()}>Cancelar</button>                         
                        <button className='btn btn-secondary text-white btnRadius' style={{ width: '30%' }} onClick={() => this.registrar()}>Hacer Corte</button>
                    </Modal.Footer>
                </Modal>
                <Toaster/>
            </>
                )
    }
}
 
export default Registrar;