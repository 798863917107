import React from 'react';
import '../css/navbar.css';
import { toast, Toaster } from 'react-hot-toast';
import { MyContext } from '../context/AuthContext';
import { Offcanvas } from 'react-bootstrap';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cuentas: [],
            totalCuentas: '',
            showOffcanvas: false
        }
    }

    static contextType = MyContext;

    componentDidMount = () => {
        if (this.context.rootState.idUsuario !== '1') {
            this.select();
        }
    }

    showOffcanvas = () => {
        this.setState({
            showOffcanvas: true
        })
    }

    hideOffcanvas = () => {
        this.setState({
            showOffcanvas: false
        })
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectHeader', fecha: this.state.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/cuentas.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            var cuentas = data.cuentas;
            cuentas = cuentas.filter((e) => e.idCuenta !== '1');
            var totalCuentas = 0;
            cuentas.forEach(element => {
                totalCuentas = totalCuentas + Number(element.saldo)
            });
            this.setState({
                cuentas: data.cuentas,
                totalCuentas: totalCuentas
            })
        }else{
            toast.error('Error al cargar cuentas.');
        }
    }

    getTotalEfectivo = () => {
        let total = 0;
        let cuentas = this.state.cuentas;
        cuentas = cuentas.filter(e => e.nombre.toLowerCase().includes('efectivo'));
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }
    
    getTotal = () => {
        let total = 0;
        let cuentas = this.state.cuentas;
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }
    
    render() { 
        return ( 
            <>
                <div className='row m-0 bg-dark py-0 navbar degradado'>
                    <div className="col-2 h-100 py-2 d-flex align-items-center">
                        <button onClick={this.props.toggleSidebar} className='btnToggle btn p-0 ms-2'><i className="bi bi-list" style={{ fontSize: '26px', color: 'white' }}></i></button>
                    </div>
                    <div className="col-1 h-100 p-0 d-none align-items-center justify-content-end">
                        <button className='btn btn-outline-light d-flex me-2' id='btnHeaderReload' onClick={() => this.select() }><i className="bi bi-arrow-clockwise"></i></button>
                    </div>
                    <div className="col-6 d-flex align-items-center p-0" style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}>
                        <p className='m-0 text-white' style={{ fontSize: '14px' }}>{this.context.rootState.ruta}</p>
                        <p className='m-0 text-white ms-1' hidden={this.context.rootState.subruta === ''}><i className="bi bi-arrow-right-short"></i></p>
                        <p className='m-0 text-white' style={{ fontSize: '14px' }}>{this.context.rootState.subruta}</p>
                    </div>
                    <div className="col-4 h-100 py-2 px-0 text-end">
                        {
                            this.state.cuentas[0] &&
                                <button className='btn h-100 p-0 px-2' style={{ color: 'white' }} onClick={this.showOffcanvas}>
                                    <div className="row m-0">
                                        <div className="col-12 p-0">
                                            <p className='m-0 mb-0 mt-auto ms-2' style={{ fontSize: '17px' }}>${this.numberWithCommas(this.getTotal())} <i className="bi bi-chevron-down"></i></p>
                                        </div>
                                    </div>
                                </button>
                        }
                    </div>
                </div>
                <Offcanvas show={this.state.showOffcanvas} onHide={this.hideOffcanvas} placement={'top'} style={{ height: 'fit-content' }}>
                    <Offcanvas.Header closeButton closeVariant='white' className='degradado' style={{ color: 'white', borderBottom: '1px solid white', height: '50px' }}>
                        <Offcanvas.Title>Saldo cuentas</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='bg-light p-0' style={{ fontSize: '14px' }}>
                        <div className='p-3 px-4'>
                            <div className="row d-flex justify-content-center">
                                {
                                    this.state.cuentas.map((e, index) =>
                                        <div className="col-6 col-md-3 col-lg-2 px-3" key={index}>
                                            <div className={"row elemento saldoCuenta px-0 py-2 mb-2 " + ((e.idCuenta === '1') ? 'border border-secondary' : '')}>
                                                <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                    <p className='m-0' style={{ fontSize: '12px' }}>{e.nombre}</p>
                                                </div>
                                                <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                    <p className='m-0'>$ {this.numberWithCommas(e.saldo)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <hr className='my-1'/>
                            <div className="row mt-2 d-flex justify-content-center">
                                <div className="col-6 col-md-4 col-lg-3 col-xl-2 px-3">
                                    <div className="row elemento px-0 py-2 mb-2 bg-info" style={{ color: 'white' }}>
                                        <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                            <p className='m-0' style={{ fontSize: '12px' }}>Total Efectivo</p>
                                        </div>
                                        <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                            <p className='m-0'>$ {this.numberWithCommas(this.getTotalEfectivo())}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 col-xl-2 px-3">
                                    <div className="row elemento px-0 py-2 bg-secondary" style={{ color: 'white' }}>
                                        <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                            <p className='m-0' style={{ fontSize: '12px' }}>Total</p>
                                        </div>
                                        <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                            <p className='m-0'>$ {this.numberWithCommas(this.getTotal())}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <Toaster/>
            </>
         );
    }
}
 
export default Navbar;