import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {MyContext} from '../../../context/AuthContext';

class MensualSucursal extends Component {
    constructor(props) {
        super(props);
        this.tablaResumen = React.createRef();
        this.state = {
            mes: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
            data: {
                ingresos: 0,
                promedioIngresos: 0,
                costosventa: 0,
                promedioCostosVentas: 0,
                gastosoperativos: 0,
                promedioGastosOperativos: 0,
                repartoutilidades: 0,
                promedioRepartoUtilidades: 0,
                inversiones: 0,
                promedioInversiones: 0,
            }
        }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        this.select();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sucursal !== prevProps.sucursal) {
            this.select();
        }
    }

    select = async () => {
        let mes = this.state.mes.split('-')[1];
        let ano = this.state.mes.split('-')[0];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectMes', mes: mes, ano: ano, sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getDisponible = (ingresos, costosventa, gastosoperativos, repartoutilidades, inversiones) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades) + Math.abs(inversiones))
        return disponible
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    buscar = () => {
        this.select();
    }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    borderResaltado = '2px solid #654E7C';
    
    render() { 
        return ( 
            <>
                <div className="col-12 mb-3">
                    <div className="" style={{ overflowX: 'auto' }}>
                        <table ref={this.tablaResumen}>
                            <thead>
                                <tr>
                                    <th className='thHeader' colSpan={4}>{this.props.nombreSucursal}</th>
                                </tr>
                                <tr>
                                    <th className='thSubheader'>Concepto</th>
                                    <th className='thSubheader'>Monto</th>
                                    <th className='thSubheader'>%</th>
                                    <th className='thSubheader'>Prom. Diario</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-end'>
                                    <td className='tdConcepto'>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: '#D1FFE3' }}><i className="bi bi-cash-coin"></i></span>
                                            </div>
                                            <input type="text" className="form-control d-flex my-auto ms-1" value={"Ingresos"} readOnly/>
                                        </div>
                                    </td>
                                    <td>$ {this.numberWithCommas(this.state.data.ingresos)}</td>
                                    <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.ingresos)}%</td>
                                    <td>$ {this.numberWithCommas(this.state.data.promedioIngresos)}</td>
                                </tr>
                                <tr className='text-end'>
                                    <td className='tdConcepto'>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: '#FEF6FF' }}><i className="bi bi-wallet2"></i></span>
                                            </div>
                                            <input type="text" className="form-control d-flex my-auto ms-1" value={"Costo de venta"} readOnly/>
                                        </div>
                                    </td>
                                    <td>$ {this.numberWithCommas(this.state.data.costosventa)}</td>
                                    <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.costosventa)}%</td>
                                    <td>$ {this.numberWithCommas(this.state.data.promedioCostosVentas)}</td>
                                </tr>
                                <tr className='text-end'>
                                    <td className='tdConcepto'>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: '#FFFADE' }}><i className="bi bi-droplet"></i></span>
                                            </div>
                                            <input type="text" className="form-control d-flex my-auto ms-1" value={"Gastos operativos"} readOnly/>
                                        </div>
                                    </td>
                                    <td>$ {this.numberWithCommas(this.state.data.gastosoperativos)}</td>
                                    <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.gastosoperativos)}%</td>
                                    <td>$ {this.numberWithCommas(this.state.data.promedioGastosOperativos)}</td>
                                </tr>
                                <tr className='text-end' style={{ fontWeight: '400' }}>
                                    <td className='tdConcepto' style={{ borderLeft: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-cash"></i></span>
                                            </div>
                                            <input type="text" className="form-control d-flex my-auto ms-1" value={"Utilidades"} readOnly/>
                                        </div>
                                    </td>
                                    <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>$ {this.numberWithCommas(this.getUtilidades(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos))}</td>
                                    <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>{this.getPorcentaje(this.state.data.ingresos, this.getUtilidades(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos))}%</td>
                                    <td style={{ borderRight: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>$ {this.numberWithCommas(this.getUtilidades(this.state.data.promedioIngresos, this.state.data.promedioCostosVentas, this.state.data.promedioGastosOperativos))}</td>
                                </tr>
                                <tr className='text-end'>
                                    <td className='tdConcepto'>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: '#D3E5EF' }}><i className="bi bi-cash-stack"></i></span>
                                            </div>
                                            <input type="text" className="form-control d-flex my-auto ms-1" value={"Reparto utilidades"} readOnly/>
                                        </div>
                                    </td>
                                    <td>$ {this.numberWithCommas(this.state.data.repartoutilidades)}</td>
                                    <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.repartoutilidades)}%</td>
                                    <td>$ {this.numberWithCommas(this.state.data.promedioRepartoUtilidades)}</td>
                                </tr>
                                <tr className='text-end'>
                                    <td className='tdConcepto'>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: '#f9d4ea' }}><i className="bi bi-graph-up-arrow"></i></span>
                                            </div>
                                            <input type="text" className="form-control d-flex my-auto ms-1" value={"Inversiones"} readOnly/>
                                        </div>
                                    </td>
                                    <td>$ {this.numberWithCommas(this.state.data.inversiones)}</td>
                                    <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.inversiones)}%</td>
                                    <td>$ {this.numberWithCommas(this.state.data.promedioInversiones)}</td>
                                </tr>
                                <tr className='text-end' style={{ fontWeight: '400' }}>
                                    <td className='tdConcepto' style={{ borderLeft: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-graph-up"></i></span>
                                            </div>
                                            <input type="text" className="form-control d-flex my-auto ms-1" value={"Balance"} readOnly/>
                                        </div>
                                    </td>
                                    <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>$ {this.numberWithCommas(this.getDisponible(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos, this.state.data.repartoutilidades, this.state.data.inversiones))}</td>
                                    <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>{this.getPorcentaje(this.state.data.ingresos, this.getDisponible(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos, this.state.data.repartoutilidades, this.state.data.inversiones))}%</td>
                                    <td style={{ borderRight: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Toaster/>
            </>
         );
    }
}
 
export default MensualSucursal;