import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {MyContext} from '../../../context/AuthContext';
import { MoonLoader } from 'react-spinners';
import colores from '../../../css/bootstrap.scss';

class Proyecto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha: this.formatDate(new Date().toLocaleDateString()),
            presupuesto: '',
            ingresos: '',
            costosventa: '',
            gastosoperativos: '',
            repartoutilidades: '',
            inversiones: '',
            cargando: true,
            saldo: 0
        }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        this.select();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sucursal !== prevProps.sucursal) {
            this.select();
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        this.selectSaldo();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectProyecto', sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                presupuesto: data.presupuesto,
                ingresos: data.ingresos,
                costosventa: data.costosventa,
                gastosoperativos: data.gastosoperativos,
                repartoutilidades: data.repartoutilidades,
                inversiones: data.inversiones,
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    selectSaldo = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectSaldoDia', fecha: this.state.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            let total = 0;
            data.data.forEach(element => {
                total = total + Number(element.saldo)
            });
            this.setState({
                saldo: total,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getAhorro = (presupuesto, costosventa, gastosoperativos) => {
        let gastos = costosventa + gastosoperativos;
        let ahorro = 0;
        if (presupuesto > gastos) {
            ahorro = presupuesto - gastos;
        }
        return ahorro
    }
    
    getExcedente = (presupuesto, costosventa, gastosoperativos) => {
        let gastos = costosventa + gastosoperativos;
        let excedente = 0;
        if (gastos > presupuesto) {
            excedente = Math.abs(presupuesto - gastos);
        }
        return excedente
    }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getDisponible = (ingresos, costosventa, gastosoperativos, repartoutilidades, inversiones) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades) + Math.abs(inversiones))
        return disponible
    }
    
    render() { 
        return ( 
            <>
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.primary} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3'>
                            <div className="" style={{ overflowX: 'auto' }}>
                                <table ref={this.tablaResumen}>
                                    <thead>
                                        <tr>
                                            <th className='thSubheader'>Concepto</th>
                                            <th className='thSubheader'>Monto</th>
                                            <th className='thSubheader'>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#D1FFE3' }}><i className="bi bi-wallet"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Presupuesto"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.presupuesto)}</td>
                                            <td>{this.getPorcentaje(this.state.presupuesto, this.state.presupuesto)}%</td>
                                        </tr>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#FEF6FF' }}><i className="bi bi-wallet2"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Costo de venta"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.costosventa)}</td>
                                            <td>{this.getPorcentaje(this.state.presupuesto, this.state.costosventa)}%</td>
                                        </tr>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#FFFADE' }}><i className="bi bi-droplet"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Gastos operativos"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.gastosoperativos)}</td>
                                            <td>{this.getPorcentaje(this.state.presupuesto, this.state.gastosoperativos)}%</td>
                                        </tr>
                                        <tr style={{ color: 'white' }} className='text-end bg-success'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: 'white' }}><i className="bi bi-piggy-bank text-success"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1 text-white" value={"Ahorro"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.getAhorro(Number(this.state.presupuesto), Number(this.state.costosventa), Number(this.state.gastosoperativos)))}</td>
                                            <td>{this.getPorcentaje(this.state.presupuesto, this.getAhorro(Number(this.state.presupuesto), Number(this.state.costosventa), Number(this.state.gastosoperativos)))}%</td>
                                        </tr>
                                        <tr style={{ color: 'white' }} className='text-end bg-danger'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: 'white' }}><i className="bi bi-graph-down-arrow text-danger"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1 text-white" value={"Excedente"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.getExcedente(Number(this.state.presupuesto), Number(this.state.costosventa), Number(this.state.gastosoperativos)))}</td>
                                            <td>{this.getPorcentaje(this.state.presupuesto, this.getExcedente(Number(this.state.presupuesto), Number(this.state.costosventa), Number(this.state.gastosoperativos)))}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="my-4" style={{ overflowX: 'auto' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='thSubheader'>Concepto</th>
                                            <th className='thSubheader'>Monto</th>
                                            <th className='thSubheader'>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#D1FFE3' }}><i className="bi bi-cash-coin"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Ingresos"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.ingresos)}</td>
                                            <td>{this.getPorcentaje(this.state.ingresos, this.state.ingresos)}%</td>
                                        </tr>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#FEF6FF' }}><i className="bi bi-wallet2"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Costo de venta"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.costosventa)}</td>
                                            <td>{this.getPorcentaje(this.state.ingresos, this.state.costosventa)}%</td>
                                        </tr>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#FFFADE' }}><i className="bi bi-droplet"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Gastos operativos"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.gastosoperativos)}</td>
                                            <td>{this.getPorcentaje(this.state.ingresos, this.state.gastosoperativos)}%</td>
                                        </tr>
                                        <tr style={{ fontWeight: '400' }} className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-cash"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Utilidades"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.getUtilidades(this.state.ingresos, this.state.costosventa, this.state.gastosoperativos))}</td>
                                            <td>{this.getPorcentaje(this.state.ingresos, this.getUtilidades(this.state.ingresos, this.state.costosventa, this.state.gastosoperativos))}%</td>
                                        </tr>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#D3E5EF' }}><i className="bi bi-cash-stack"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Reparto utilidades"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.repartoutilidades)}</td>
                                            <td>{this.getPorcentaje(this.state.ingresos, this.state.repartoutilidades)}%</td>
                                        </tr>
                                        <tr className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#f9d4ea' }}><i className="bi bi-graph-up-arrow"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Inversiones"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.state.inversiones)}</td>
                                            <td>{this.getPorcentaje(this.state.ingresos, this.state.inversiones)}%</td>
                                        </tr>
                                        <tr style={{ fontWeight: '400' }} className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-graph-up"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Balance"} readOnly/>
                                                </div>
                                            </td>
                                            <td>$ {this.numberWithCommas(this.getDisponible(this.state.ingresos, this.state.costosventa, this.state.gastosoperativos, this.state.repartoutilidades, this.state.inversiones))}</td>
                                            <td>{this.getPorcentaje(this.state.ingresos, this.getDisponible(this.state.ingresos, this.state.costosventa, this.state.gastosoperativos, this.state.repartoutilidades, this.state.inversiones))}%</td>
                                        </tr>
                                        <tr style={{ fontWeight: '400' }} className='text-end'>
                                            <td className='tdConcepto'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-bank"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control d-flex my-auto ms-1" value={"Disponible"} readOnly/>
                                                </div>
                                            </td>
                                            <td colSpan={2}>$ {this.numberWithCommas(this.state.saldo)}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
                <Toaster/>
            </>
         );
    }
}
 
export default Proyecto;