import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {MyContext} from '../../../../context/AuthContext';
import Calendar from './Calendar';
import { startOfWeek, endOfWeek, getYear, getMonth, getWeek, eachDayOfInterval, isEqual, parseISO } from 'date-fns';
import { Modal } from 'react-bootstrap';
import { MoonLoader } from 'react-spinners';
import colores from '../../../../css/bootstrap.scss';

class Calendario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: this.formatDate(new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString()),
            fechaFin: this.formatDate(new Date().toLocaleDateString()),
            data: {
                ingresos: 0,
                costosventa: 0,
                gastosoperativos: 0,
                repartoutilidades: 0
            },
            dataCalendario: [],
            showModal: false,
            cargando: true
        }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        this.select();
        this.selectCalendario();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sucursal !== prevProps.sucursal) {
            this.select();
            this.selectCalendario();
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectRango', desde: this.state.fechaInicio, hasta: this.state.fechaFin, sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }
    
    selectCalendario = async () => {
        let fechaInicio = this.formatDate(startOfWeek(parseISO(this.state.fechaInicio), {weekStartsOn: 1}).toLocaleDateString());
        let fechaFin = this.formatDate(endOfWeek(parseISO(this.state.fechaFin), {weekStartsOn: 1}).toLocaleDateString());
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectCalendario', desde: fechaInicio, hasta: fechaFin, sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            var datosFinales = [];
            var datos = data.data;
            var anos = [];
            datos.forEach(element => {
                element.fechaObj = new Date(element.fecha + ' 00:00');
                anos.push(getYear(element.fechaObj));
            });
            let uniqueAnos = [...new Set(anos)];
            uniqueAnos.forEach(eUAnos => {
                datosFinales.push({ano: eUAnos, meses: []});
                var meses = [];
                var datosAno = datos.filter(e => getYear(e.fechaObj) === eUAnos);
                datosAno.forEach(eAno => {
                    meses.push(getMonth(eAno.fechaObj));
                });
                let uniqueMeses = [...new Set(meses)];
                uniqueMeses.forEach(eUMeses => {
                    datosFinales.find(eDatosFinales => eDatosFinales.ano === eUAnos).meses.push({mes: eUMeses, semanas: []});
                    var semanas = [];
                    var datosMes = datosAno.filter(e => getMonth(e.fechaObj) === eUMeses);
                    datosMes.forEach(eMes => {
                        semanas.push(getWeek(eMes.fechaObj, {weekStartsOn: 1}));
                    }); 
                    let uniqueSemanas = [...new Set(semanas)];
                    uniqueSemanas.forEach(eUSemanas => {
                        var datosSemana = [];
                        var primerDiaSemana = startOfWeek(datosMes.filter(e => getWeek(e.fechaObj, {weekStartsOn: 1}) === eUSemanas)[0].fechaObj, {weekStartsOn: 1});
                        var ultimoDiaSemana = datosMes.filter(e => getWeek(e.fechaObj, {weekStartsOn: 1}) === eUSemanas);
                        ultimoDiaSemana = endOfWeek(ultimoDiaSemana[ultimoDiaSemana.length - 1].fechaObj, {weekStartsOn: 1});
                        eachDayOfInterval({ start: primerDiaSemana, end: ultimoDiaSemana }).forEach(e => {
                            datosSemana.push(datos.find((eDatos) => isEqual(eDatos.fechaObj, e)));
                        });
                        datosFinales.find(eDatosFinales => eDatosFinales.ano === eUAnos).meses.find(eDetalles => eDetalles.mes === eUMeses).semanas.push({semana: eUSemanas, datos: datosSemana});
                    });
                });
            });
            var datosSemanas = [];
            datosFinales.forEach(elementA => {
                elementA.meses.forEach(elementB => {
                    elementB.semanas.forEach(elementC => {
                        if (datosSemanas[datosSemanas.length - 1]) {
                            if (this.arrayEquals(datosSemanas[datosSemanas.length - 1], elementC.datos)) {
                                
                            }else{
                                datosSemanas.push(elementC.datos);
                            }
                        }else{
                            datosSemanas.push(elementC.datos);
                        }
                    });
                });
            });
            datosSemanas.forEach(element => {
                element.forEach((e, index) => {
                    if (index === (element.length - 1)) {                        
                        element.mes = this.getMonthName(getMonth(e.fechaObj)) + ' ' + getYear(e.fechaObj);
                    }
                    if (this.ordenarMenor(element)[0].fecha === e.fecha) {
                        e.orden = 'menor';
                    }else{
                        if (this.ordenarMenor(element)[1].fecha === e.fecha) {
                            e.orden = 'menor';
                        }else{
                            if (this.ordenarMenor(element)[6].fecha === e.fecha) {
                                e.orden = 'mayor';
                            }else{
                                if (this.ordenarMenor(element)[5].fecha === e.fecha) {
                                    e.orden = 'mayor';
                                }else{
                                    e.orden = '';
                                }
                            }
                        }
                    }
                });
            });
            this.setState({
                dataCalendario: datosSemanas,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    ordenarMenor = (datos) => {
        const datosManipular = datos.map(obj => ({...obj}));
        let datosOrdenados = datosManipular.sort((a,b) => (a.ingresos) - (b.ingresos));
        return datosOrdenados;
    }

    getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);      
        return date.toLocaleString('es-MX', { month: 'long' });
      }

    arrayEquals(a, b) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
      }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getDisponible = (ingresos, costosventa, gastosoperativos, repartoutilidades) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades))
        return disponible
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    buscar = () => {
        if (this.state.fechaFin < this.state.fechaInicio) {
            toast.error('La fecha final no puede ser menor a la inicial');
        }else{
            this.select();
            this.selectCalendario();
        }
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false
        })
    }
    
    render() { 
        return ( 
            <>
                <div className={"col-4 py-0 px-1 " + (this.props.sucursal ? 'mt-2' : '')}>
                    <div className="inputGroup">
                        <label htmlFor="desde">Desde</label>
                        <input type="date" id='desde' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '14px' }} name="fechaInicio" value={this.state.fechaInicio} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <div className="inputGroup">
                        <label htmlFor="hasta">Hasta</label>
                        <input type="date" id='hasta' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '14px' }} name="fechaFin" value={this.state.fechaFin} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.primary} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3'>
                            <div className="mt-3 p-0">
                                {
                                    (this.state.dataCalendario.length > 0 && this.state.showModal === false) &&
                                        <Calendar data={this.state.dataCalendario} showModal={this.state.showModal}/>
                                }
                            </div>
                        </div>
                }
                <button className='btn btn-sm mt-1' style={{ textDecoration: 'underline' }} onClick={() => this.showModal()}>Ver en pantalla completa</button>

                <Modal show={this.state.showModal} onHide={this.hideModal} fullscreen>
                    <Modal.Header className='degradado' closeButton closeVariant='white'>

                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        {
                            (this.state.dataCalendario.length > 0 && this.state.showModal === true) &&
                                <Calendar data={this.state.dataCalendario} showModal={this.state.showModal}/>
                        }
                    </Modal.Body>
                </Modal>

                <Toaster/>
            </>
         );
    }
}
 
export default Calendario;