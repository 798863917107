import React, { Component } from 'react';
import colores from '../../../css/bootstrap.scss';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { MyContext } from '../../../context/AuthContext';

class Sucursales extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            sucursales: [],
            showModal: false,
            showModalEditar: false,
            idSucursal: '',
            nombreSucursal: '',
            presupuestoSucursal: '',
            cerrado: false,
        }
    }

    static contextType = MyContext;

    componentDidMount = async () => {
        await this.context.cambiarRuta('Configuración');
        this.context.cambiarSubruta('Sucursales');
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
        this.select();
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 50) + 'px';
                let width = document.getElementsByClassName('contenedorPrincipal')[0].offsetWidth;
                document.getElementsByClassName("rowBtnAgregar")[0].style.width = (width) + 'px';
        }
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        this.setState({
            sucursales: data.sucursales
        })
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false,
            nombreSucursal: '',
            presupuestoSucursal: '',
            cerrado: false,
        })
    }
    
    showModalEditar = (id, nombre, presupuesto, cerrado) => {
        this.setState({
            showModalEditar: true,
            idSucursal: id,
            nombreSucursal: nombre,
            presupuestoSucursal: presupuesto,
            cerrado: cerrado,
        })
    }

    hideModalEditar = () => {
        this.setState({
            showModalEditar: false,
            idSucursal: '',
            nombreSucursal: '',
            presupuestoSucursal: '',
            cerrado: false
        })
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    agregar = async (e) => {
        e.preventDefault();
        if (this.state.nombreSucursal !== '') {
            if (this.state.sucursales.find(e => e.nombre.toLowerCase().trim() === this.state.nombreSucursal.toLocaleLowerCase().trim())) {
                toast.error('Ya existe una sucursal con este nombre.');
            }else{
                const requestOptions = {
                    method: 'POST',
                    header: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'insert', nombre: this.state.nombreSucursal.trim(), presupuesto: this.state.presupuestoSucursal, cerrado: this.state.cerrado, empresa: this.context.rootState.nombre })
                };
                const response = await fetch('http://regventas.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                    toast.success(data.mensaje);
                    this.hideModal();
                    this.select();
                    this.reloadHeader();
                }else{
                    toast.error('Hubo un error al realizar la petición.');
                    console.log(data.mensaje);
                }
            }
        }else{
            toast.error("El nombre de la sucursal está vacío.")
        }
    }
    
    editar = async (e) => {
        e.preventDefault();
        if (this.state.nombreSucursal !== '') {
            if (this.state.sucursales.find(e => e.nombre.toLowerCase().trim() === this.state.nombreSucursal.toLocaleLowerCase().trim()) && (this.state.idSucursal !== this.state.sucursales.find(e => e.nombre.toLowerCase().trim() === this.state.nombreSucursal.toLocaleLowerCase().trim()).idSucursal)) {
                toast.error('Ya existe una sucursal con este nombre.');
            }else{
                const requestOptions = {
                    method: 'POST',
                    header: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'update', idSucursal: this.state.idSucursal, nombre: this.state.nombreSucursal.trim(), cerrado: this.state.cerrado, presupuesto: this.state.presupuestoSucursal, empresa: this.context.rootState.nombre })
                };
                const response = await fetch('http://regventas.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                    toast.success(data.mensaje);
                    this.hideModalEditar();
                    this.select();
                    this.reloadHeader();
                }else{
                    toast.error('Hubo un error al realizar la petición.');
                    console.log(data.mensaje);
                }
            }
        }else{
            toast.error("El nombre de la sucursal está vacío.")
        }
    }

    borrar = async (id) => {
        if (window.confirm("Eliminar permanentemente la sucursal?")) {
            const requestOptions = {
                method: 'POST',
                header: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idSucursal: id, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.select();
                this.reloadHeader();
            }else{
                toast.error('Hubo un error al realizar la petición.');
                console.log(data.mensaje);
            }
        }
    }

    reloadHeader = () => {
        document.getElementById('btnHeaderReload').click();
    }
    
    render() { 
        return ( 
            <>
            <div className="contenedorPrincipal p-0 bg-light">
                <div className="row m-0 rowBtnAgregar">
                    <div className="col-12 d-flex align-items-center">
                            <button className='btnAgregar mx-auto' onClick={this.showModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill={colores.secondary} className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>                                </svg>
                            </button>
                        </div>
                </div>
                <div className='mx-auto' style={{ maxWidth: '500px' }}>
                    <div className="row w-100 m-0 mb-4">
                        <div className="col-12 mt-0">
                            {
                                this.state.sucursales[0] ?
                                    this.state.sucursales.map((e, index) =>
                                        <div className="elemento mt-3 d-flex align-items-center" key={index}>
                                            <p className='m-0'>{e.nombre}</p>
                                            <button className='btn btn-outline-secondary ms-auto me-2 btnEditar' onClick={() => this.showModalEditar(e.idSucursal, e.nombre, e.presupuesto, e.cerrado)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={colores.secondary} className="bi bi-pen" viewBox="0 0 16 16">
                                                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                                </svg>
                                            </button>
                                            <button className='btn btn-outline-danger btnBorrar' onClick={() => this.borrar(e.idSucursal)} disabled={e.idSucursal === '1'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={colores.danger} className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    )
                                :
                                <div className="elemento">
                                    <p className='text-center m-0'>No hay sucursales registradas</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={this.state.showModal} onHide={this.hideModal} centered>    
            <form action="" onSubmit={this.agregar}>
                <Modal.Header className='degradado'>
                    <h5 style={{ color: 'white' }} className='m-0'>Agregar Sucursal</h5>
                </Modal.Header>
                <Modal.Body className='bg-light' style={{ color: 'black' }}>
                        <label htmlFor="nombreSucursal">Nombre de la sucursal</label>
                        <input className='form-control boxShadow bg-white mt-1' style={{ color: 'black' }} type="text" name="nombreSucursal" id="nombreSucursal" autoComplete='off' value={this.state.nombreSucursal} onChange={this.onChangeValue}/>
                        <label className='mt-2'>Presupuesto</label>
                        <div className="input-group elemento p-0">
                            <span className='input-group-text'>$</span>
                            <NumericFormat thousandSeparator=',' prefix='$' value={this.state.presupuestoSucursal} className='form-control text-end' style={{ color: 'black' }} defaultValue={''} placeholder='$0.0' onValueChange={(values) => { const { value } = values; this.setState({ presupuestoSucursal: Number(value) }); }}/>
                        </div>
                        <label htmlFor="cerrado" className='mt-2'>¿Cerrado?</label>
                        <select name="cerrado" id='cerrado' className='form-control boxShadow mt-1 bg-white' style={{ color: 'black' }} value={this.state.cerrado} onChange={this.onChangeValue}>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                        </select>
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                    <button className='btn btnRadius btn-primary text-white mx-3' style={{ width: '30%' }} type='button' onClick={this.hideModal}>Cancelar</button>
                    <button className='btn btnRadius btn-secondary text-white mx-3' style={{ width: '30%' }} type='subtmit'>Agregar</button>
                </Modal.Footer>
            </form>
            </Modal>
            <Modal show={this.state.showModalEditar} onHide={this.hideModalEditar} centered>    
            <form action="" onSubmit={this.editar}>
                <Modal.Header className='degradado'>
                    <h5 style={{ color: 'white' }} className='m-0'>Editar Sucursal</h5>
                </Modal.Header>
                <Modal.Body className='bg-light' style={{ color: 'black' }}>
                        <label htmlFor="nombreSucursal">Nombre de la sucursal</label>
                        <input className='form-control boxShadow bg-white mt-1' style={{ color: 'black' }} type="text" name="nombreSucursal" id="nombreSucursal" autoComplete='off' value={this.state.nombreSucursal} onChange={this.onChangeValue}/>
                        <label className='mt-2'>Presupuesto</label>
                        <div className="input-group elemento p-0">
                            <span className='input-group-text'>$</span>
                            <NumericFormat thousandSeparator=',' prefix='$' value={this.state.presupuestoSucursal} className='form-control text-end' style={{ color: 'black' }} defaultValue={''} placeholder='$0.0' onValueChange={(values) => { const { value } = values; this.setState({ presupuestoSucursal: Number(value) }); }}/>
                        </div>
                        <label htmlFor="cerrado" className='mt-2'>¿Cerrado?</label>
                        <select name="cerrado" id='cerrado' className='form-control boxShadow bg-white mt-1' style={{ color: 'black' }} value={this.state.cerrado} onChange={this.onChangeValue}>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                        </select>
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                    <button className='btn btnRadius btn-primary text-white mx-3' style={{ width: '30%' }} type='button' onClick={this.hideModalEditar}>Cancelar</button>
                    <button className='btn btnRadius btn-secondary text-white mx-3' style={{ width: '30%' }} type='subtmit'>Editar</button>
                </Modal.Footer>
            </form>
            </Modal>
            <Toaster/>
            </>
         );
    }
}
 
export default Sucursales;