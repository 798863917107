import React, { Component } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast, { Toaster } from 'react-hot-toast';
import { Pie } from '@nivo/pie';
import {MyContext} from '../../../context/AuthContext';
import { MoonLoader } from 'react-spinners';
import colores from '../../../css/bootstrap.scss';
import SwitchSelector from 'react-switch-selector';

class RangoGO extends Component {
    constructor(props) {
        super(props);
        this.tablaResumen = React.createRef();
        this.state = {
            fechaInicio: this.formatDate(new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString()),
            fechaFin: this.formatDate(new Date().toLocaleDateString()),
            data: [],
            cargando: true,
            vista: 'numeros'
        }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        this.select();
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectRangoGO', desde: this.state.fechaInicio, hasta: this.state.fechaFin, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data.data,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    subtractDays(numOfDays, date = new Date()) {
        date.setDate(date.getDate() - numOfDays);
        return date;
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDateRange = (values) => {
        this.setState({
            rango: values
        })
    }

    cambiarVista = (newValue) => {
        this.setState({
            vista: newValue
        })
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getDisponible = (ingresos, costosventa, gastosoperativos, repartoutilidades) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades))
        return disponible
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    buscar = () => {
        this.select();
    }
    
    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    getTotal = () => {
        let total = 0;
        this.state.data.forEach(element => {
            total = total + Number(element.monto)
        });
        return total
    }

    CenteredMetric = ({ centerX, centerY }) => {
        let total = 0
        total = this.getTotal();
        return (
            <>                
                <text
                    x={centerX}
                    y={centerY - 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    Balance
                </text>
                <text
                    x={centerX}
                    y={centerY + 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    ${this.numberWithCommas(total)}
                </text>
            </>
        )
    }
    
    render() { 
        return ( 
            <>
                <div className={"col-4 py-0 px-1 " + (this.props.sucursal ? 'mt-2' : '')}>
                    <div className="inputGroup">
                        <label htmlFor="desde">Desde</label>
                        <input type="date" id='desde' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '14px' }} name="fechaInicio" value={this.state.fechaInicio} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <div className="inputGroup">
                        <label htmlFor="hasta">Hasta</label>
                        <input type="date" id='hasta' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '14px' }} name="fechaFin" value={this.state.fechaFin} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.primary} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3'>
                            <div className="mt-3 mx-auto" style={{ height: '40px', width: '200px' }}>
                                <SwitchSelector
                                    forcedSelectedIndex={[{label: "Datos", value: "numeros"},{label: "Gráficos", value: "graficos"}].findIndex(e => e.value === this.state.vista)}
                                    onChange={this.cambiarVista}
                                    wrapperBorderRadius={5}
                                    optionBorderRadius={5}
                                    backgroundColor={colores.primary}
                                    selectedBackgroundColor={colores.dark}
                                    options={[
                                        {label: <i className="bi bi-table text-white"></i>, value: "numeros"},
                                        {label: <i className="bi bi-pie-chart text-white"></i>, value: "graficos"}
                                    ]}
                                />
                            </div>
                            {
                                this.state.vista === 'numeros' ?
                                    <>
                                        <div className="mt-2" style={{ overflowX: 'auto' }}>
                                            <table ref={this.tablaResumen}>
                                                <thead>
                                                    <tr>
                                                        <th className='thSubheader'>Servicio</th>
                                                        <th className='thSubheader'>Monto</th>
                                                        <th className='thSubheader'>%</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-end bg-success' style={{ color: 'white' }}>
                                                        <td className='text-start'>Total</td>
                                                        <td>${this.numberWithCommas(this.getTotal())}</td>
                                                        <td>{this.getPorcentaje(this.getTotal(), this.getTotal())}%</td>
                                                    </tr>
                                                    {this.state.data.map((e, index) =>
                                                        <tr key={index} className="text-end">
                                                            <td className='text-start'>{e.nombre}</td>
                                                            <td>${this.numberWithCommas(e.monto)}</td>
                                                            <td>{this.getPorcentaje(this.getTotal(), e.monto)}%</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <DownloadTableExcel
                                            filename="Resumen Anual"
                                            sheet="Resumen Anual"
                                            currentTableRef={this.tablaResumen.current}
                                            >
                                        <button className='btn mt-1 mb-4' style={{ padding: '0px', textDecoration: 'underline', fontSize: '14px', color: 'gray' }}>Descargar tabla</button>
                                        </DownloadTableExcel>
                                    </>
                                :
                                <div className="elemento py-3 my-4">
                                    <div className="elemento p-0 d-flex mx-auto" style={{ width: 'fit-content', borderRadius: '100%', backgroundColor: 'white', border: '3px solid #654E7C' }}>
                                        <Pie
                                            innerRadius={0.5}
                                            width={320}
                                            height={320}
                                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                            colors={{ scheme: 'pastel2' }}
                                            arcLabel={e => `$${this.numberWithCommas(e.value)}`}
                                            enableArcLinkLabels={false}
                                            tooltip={({ datum: {label, color} }) =>
                                                <div className="elemento p-1" style={{ border: '2px solid ' + color }}>
                                                    <p className='m-0'>{label}</p>
                                                </div>
                                            }
                                            layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', this.CenteredMetric]}
                                            data={this.state.data.filter(e => e.value !== 0)}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                }
                <Toaster/>
            </>
         );
    }
}
 
export default RangoGO;