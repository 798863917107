import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const app = firebase.initializeApp({
    apiKey: "AIzaSyA-Hl2Gs31liEvAk46Nr-b1FtdHYqw-esg",
    authDomain: "panaderialazcano.firebaseapp.com",
    projectId: "panaderialazcano",
    storageBucket: "panaderialazcano.appspot.com",
    messagingSenderId: "793783372231",
    appId: "1:793783372231:web:465a163f510a1d034831e2"
});

export const auth = firebase.auth();
export default app;