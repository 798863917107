import React from 'react';
import {MyContext} from '../context/AuthContext';
import '../css/inicio.css';
import toast, { Toaster } from 'react-hot-toast';
import MensualSucursal from './Registros/General/MensualSucursal';
import { MoonLoader } from 'react-spinners';
import colores from '../css/bootstrap.scss';

class Inicio extends React.Component {

    static contextType = MyContext;

    constructor(){
        super();
        this.state = {
            mes: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
            ruta: 'Inicio',
            dataMensual: {
                ingresos: 0,
                promedioIngresos: 0,
                costosventa: 0,
                promedioCostosVentas: 0,
                gastosoperativos: 0,
                promedioGastosOperativos: 0,
                repartoutilidades: 0,
                promedioRepartoUtilidades: 0,
                inversiones: 0,
                promedioInversiones: 0,
            },
            sucursales: [],
            cargando: true
        }
    }

    componentDidMount = async () => {
        this.context.cambiarRuta('Inicio');
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
        this.selectMensual();
        this.selectSucursales();
    }

    selectMensual = async () => {
        let mes = this.state.mes.split('-')[1];
        let ano = this.state.mes.split('-')[0];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectMes', mes: mes, ano: ano, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                dataMensual: data
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    selectSucursales = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                sucursales: data.sucursales,
                cargando: false
            })
        }else{
            toast.error('Error al cargar sucursales');
            console.log(data.mensaje);
        }
    }

    volver = async () => {
        if (this.context.rootState.saved) { 
            await this.setState({
                ruta: 'Inicio'
            });
            this.getHeightWindow();
            this.selectCorte();
            this.selectDatosCorte();   
        }else{
            if (window.confirm('¿Continuar sin guardar cambios?')) {                
                await this.setState({
                    ruta: 'Inicio'
                });
                this.getHeightWindow();
                this.selectCorte();
                this.selectDatosCorte();  
                this.context.saveChanges();
            }
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 50) + 'px';
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getDisponible = (ingresos, costosventa, gastosoperativos, repartoutilidades, inversiones) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades) + Math.abs(inversiones))
        return disponible
    }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    getTotalEfectivo = () => {
        let total = 0;
        let cuentas = this.state.cuentas;
        cuentas = cuentas.filter(e => e.nombre.includes('Efectivo'));
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }

    getTotal = () => {
        let total = 0;
        let cuentas = this.state.cuentas;
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }

    borderResaltado = '2px solid #654E7C';

    render() { 
            return ( 
            <>
                <div className="contenedorPrincipal p-0 bg-light">
                    {
                        this.state.cargando ? 
                            <div className='d-flex justify-content-center mt-5'>
                                <div className="text-start">
                                    <MoonLoader color={colores.primary} size={200}/>
                                </div>
                            </div>
                        :
                            <div className='contenido pt-3 mx-auto' style={{ maxWidth: '500px' }}>
                                <div className="row w-100 m-0">
                                    <div className="row p-0 m-0 d-flex justify-content-center">
                                        <div className="col-6">
                                                <div className="inputGroup">
                                                    <label htmlFor="mes">Mes</label>
                                                    <input type="month" id="mes" className='form-control inputUnderline text-center p-0 mx-auto text-white' style={{ borderRadius: '0px', fontSize: '16px', width: 'fit-content', textTransform: 'capitalize' }} name="mes" value={this.state.mes} readOnly onChange={this.onChangeValue}/>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2 mb-3">
                                        <div className="" style={{ overflowX: 'auto' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className='thHeader' colSpan={4}>General</th>
                                                    </tr>
                                                    <tr>
                                                        <th className='thSubheader'>Concepto</th>
                                                        <th className='thSubheader'>Monto</th>
                                                        <th className='thSubheader'>%</th>
                                                        <th className='thSubheader'>Prom. Diario</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#D1FFE3' }}><i className="bi bi-cash-coin"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Ingresos"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.ingresos)}</td>
                                                        <td>{this.getPorcentaje(this.state.dataMensual.ingresos, this.state.dataMensual.ingresos)}%</td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.promedioIngresos)}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#FEF6FF' }}><i className="bi bi-wallet2"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Costo de venta"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.costosventa)}</td>
                                                        <td>{this.getPorcentaje(this.state.dataMensual.ingresos, this.state.dataMensual.costosventa)}%</td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.promedioCostosVentas)}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#FFFADE' }}><i style={{ color: 'black' }} className="bi bi-droplet"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Gastos operativos"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.gastosoperativos)}</td>
                                                        <td>{this.getPorcentaje(this.state.dataMensual.ingresos, this.state.dataMensual.gastosoperativos)}%</td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.promedioGastosOperativos)}</td>
                                                    </tr>
                                                    <tr className='text-end' style={{ fontWeight: '400' }}>
                                                        <td className='tdConcepto' style={{ borderLeft: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-cash"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Utilidades"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>$ {this.numberWithCommas(this.getUtilidades(this.state.dataMensual.ingresos, this.state.dataMensual.costosventa, this.state.dataMensual.gastosoperativos))}</td>
                                                        <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>{this.getPorcentaje(this.state.dataMensual.ingresos, this.getUtilidades(this.state.dataMensual.ingresos, this.state.dataMensual.costosventa, this.state.dataMensual.gastosoperativos))}%</td>
                                                        <td style={{ borderRight: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>$ {this.numberWithCommas(this.getUtilidades(this.state.dataMensual.promedioIngresos, this.state.dataMensual.promedioCostosVentas, this.state.dataMensual.promedioGastosOperativos))}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#D3E5EF' }}><i className="bi bi-cash-stack"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Reparto utilidades"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.repartoutilidades)}</td>
                                                        <td>{this.getPorcentaje(this.state.dataMensual.ingresos, this.state.dataMensual.repartoutilidades)}%</td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.promedioRepartoUtilidades)}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#f9d4ea' }}><i className="bi bi-graph-up-arrow"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Inversiones"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.inversiones)}</td>
                                                        <td>{this.getPorcentaje(this.state.dataMensual.ingresos, this.state.dataMensual.inversiones)}%</td>
                                                        <td>$ {this.numberWithCommas(this.state.dataMensual.promedioInversiones)}</td>
                                                    </tr>
                                                    <tr className='text-end' style={{ fontWeight: '400' }}>
                                                        <td className='tdConcepto' style={{ borderLeft: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-graph-up"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Balance"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>$ {this.numberWithCommas(this.getDisponible(this.state.dataMensual.ingresos, this.state.dataMensual.costosventa, this.state.dataMensual.gastosoperativos, this.state.dataMensual.repartoutilidades, this.state.dataMensual.inversiones))}</td>
                                                        <td style={{ borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}>{this.getPorcentaje(this.state.dataMensual.ingresos, this.getDisponible(this.state.dataMensual.ingresos, this.state.dataMensual.costosventa, this.state.dataMensual.gastosoperativos, this.state.dataMensual.repartoutilidades, this.state.dataMensual.inversiones))}%</td>
                                                        <td style={{ borderRight: this.borderResaltado, borderTop: this.borderResaltado, borderBottom: this.borderResaltado }}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {(this.state.sucursales.length > 1) && this.state.sucursales.map((e,index) =>
                                        <MensualSucursal key={index} sucursal={e.idSucursal} nombreSucursal={e.nombre}/>
                                    )}
                                </div>
                            </div>
                    }
                </div>
                <Toaster/>
            </>
                )
    }
}
 
export default Inicio;